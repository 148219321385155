import { Checkbox, Col, DatePicker, Divider, Input, Radio, Row, Select } from 'antd'
import React, { useState } from 'react'
import {
  ADS_SEARCH_COMPANY,
  ADS_SEARCH_KEYWORD,
  ADS_END_DATE,
  ADS_START_DATE,
  ADS_LOCATION,
  ADS_CHANNEL,
  ADS_FORMAT,
  LS_KEY,
  ADS_DATA,
  ADS_LOADER,
  ADS_HOME_STATE,
  ADS_NO_DATA,
  ADS_PAGINATION,
  EMPTY_ADS_PREVIEW_DATA,
  AG_AC_TOKEN,
  SPINNER,
  CLEANED_DATA_COUNT,
  HAS_MORE,
  META_ADS_PAGINATION_DATA,
} from '@utils/constants'
import moment from 'moment'
import companySearchIcon from '@images/AdsPreview/companySearch.png'
import keywordSearchIcon from '@images/AdsPreview/keywordSearch.png'
import filterArrow from '@images/AdsPreview/filterArrow.png'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Text from '@components/Text/text'
import { notify } from '@utils/notify'
import { baseURL, RequestService } from '@services/requests'
import styles from './searchBar.module.css'

const { Option } = Select
const SearchBar = ({ emptyAdsData }) => {
  const [selectedOptionsFormat, setSelectedOptionsFormat] = useState([])
  const [selectedOptionsChannel, setSelectedOptionsChannel] = useState('Google')
  const [locationPlaceholder, setLocationPlaceholder] = useState('Location: Anywhere')
  const [selectedLocation, setSelectedLocation] = useState([])
  const dispatch = useDispatch()
  const searchKeyword = useSelector(state => state?.adsPreview?.searchKeyword)
  const selectedChannel = useSelector(state => state?.adsPreview?.channels)
  const location = useSelector(state => state?.adsPreview?.location)
  const adsFormats = useSelector(state => state?.adsPreview?.formats)
  const loading = useSelector(state => state?.adsPreview?.loading)
  const moreDataLoader = useSelector(state => state?.adsPreview?.moreDataLoader)
  const Metacountries = [
    { value: '', label: 'Anywhere' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IN', label: 'India' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'CA', label: 'Canada' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'BE', label: 'Belgium' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CO', label: 'Colombia' },
    { value: 'HR', label: 'Croatia' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EG', label: 'Egypt' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'DE', label: 'Germany' },
    { value: 'GR', label: 'Greece' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JP', label: 'Japan' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MX', label: 'Mexico' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NO', label: 'Norway' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PA', label: 'Panama' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PL', label: 'Poland' },
    { value: 'RU', label: 'Russia' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SG', label: 'Singapore' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'KR', label: 'South Korea' },
    { value: 'ES', label: 'Spain' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TR', label: 'Turkey' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'PT', label: 'Portugal' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'IS', label: 'Iceland' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'KE', label: 'Kenya' },
    { value: 'HU', label: 'Hungary' },
    { value: 'MA', label: 'Morocco' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'RO', label: 'Romania' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'QA', label: 'Qatar' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'HN', label: 'Honduras' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'PS', label: 'Palestine' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'GH', label: 'Ghana' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'MT', label: 'Malta' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'MV', label: 'Maldives' },
    { value: 'OM', label: 'Oman' },
    { value: 'MK', label: 'North Macedonia' },
    { value: 'LV', label: 'Latvia' },
    { value: 'EE', label: 'Estonia' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AL', label: 'Albania' },
    { value: 'NP', label: 'Nepal' },
    { value: 'MO', label: 'Macau' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'SN', label: 'Senegal' },
    { value: 'GE', label: 'Georgia' },
    { value: 'BN', label: 'Brunei' },
    { value: 'UG', label: 'Uganda' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'BB', label: 'Barbados' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'LY', label: 'Libya' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'BW', label: 'Botswana' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'NA', label: 'Namibia' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'MD', label: 'Moldova' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'BY', label: 'Belarus' },
    { value: 'JE', label: 'Jersey' },
    { value: 'GU', label: 'Guam' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'HT', label: 'Haiti' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'AF', label: 'Afghanistan' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'GY', label: 'Guyana' },
    { value: 'AM', label: 'Armenia' },
    { value: 'MW', label: 'Malawi' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GM', label: 'Gambia' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'BJ', label: 'Benin' },
    { value: 'AD', label: 'Andorra' },
    { value: 'GD', label: 'Grenada' },
    { value: 'VI', label: 'United States Virgin Islands' },
    { value: 'BZ', label: 'Belize' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'ML', label: 'Mali' },
    { value: 'AO', label: 'Angola' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'MC', label: 'Monaco' },
    { value: 'TG', label: 'Togo' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'CD', label: 'Democratic Republic of the Congo' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LA', label: 'Laos' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'VG', label: 'British Virgin Islands' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'DM', label: 'Dominica' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'AX', label: 'Åland Islands' },
    { value: 'SM', label: 'San Marino' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'NE', label: 'Niger' },
    { value: 'CG', label: 'Republic of the Congo' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'GN', label: 'Guinea' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'BI', label: 'Burundi' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'WS', label: 'Samoa' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'FK', label: 'Falkland Islands' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'TO', label: 'Tonga' },
    { value: 'KM', label: 'Comoros' },
    { value: 'PW', label: 'Palau' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'SO', label: 'Somalia' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'VA', label: 'Vatican City' },
    { value: 'TD', label: 'Chad' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'ST', label: 'São Tomé and Principe' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'NR', label: 'Nauru' },
    { value: 'RE', label: 'Réunion' },
    { value: 'LR', label: 'Liberia' },
    { value: 'ZW', label: 'Zimbabwe' },
    { value: 'CI', label: "Côte d'Ivoire" },
    { value: 'MM', label: 'Myanmar' },
    { value: 'AN', label: 'Netherlands Antilles' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'BQ', label: 'Bonaire' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'TF', label: 'French Southern and Antarctic Lands' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'HM', label: 'Heard Island and McDonald Islands' },
    { value: 'XK', label: 'Kosovo' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'PN', label: 'Pitcairn Islands' },
    { value: 'BL', label: 'Saint Barthélemy' },
    { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: 'MF', label: 'Saint Martin' },
    { value: 'PM', label: 'Saint Pierre and Miquelon' },
    { value: 'SX', label: 'Sint Maarten' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen' },
    { value: 'TL', label: 'East Timor' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'WF', label: 'Wallis and Futuna' },
    { value: 'EH', label: 'Western Sahara' },
  ]
  const countries = [
    { value: null, label: 'Anywhere' },
    { value: '2008', label: 'Albania' },
    { value: '2010', label: 'Antarctica' },
    { value: '2012', label: 'Algeria' },
    { value: '2016', label: 'American Samoa' },
    { value: '2020', label: 'Andorra' },
    { value: '2024', label: 'Angola' },
    { value: '2028', label: 'Antigua and Barbuda' },
    { value: '2031', label: 'Azerbaijan' },
    { value: '2032', label: 'Argentina' },
    { value: '2036', label: 'Australia' },
    { value: '2040', label: 'Austria' },
    { value: '2044', label: 'The Bahamas' },
    { value: '2048', label: 'Bahrain' },
    { value: '2050', label: 'Bangladesh' },
    { value: '2051', label: 'Armenia' },
    { value: '2052', label: 'Barbados' },
    { value: '2056', label: 'Belgium' },
    { value: '2060', label: 'Bermuda' },
    { value: '2064', label: 'Bhutan' },
    { value: '2068', label: 'Bolivia' },
    { value: '2070', label: 'Bosnia and Herzegovina' },
    { value: '2072', label: 'Botswana' },
    { value: '2074', label: 'Bouvet Island' },
    { value: '2076', label: 'Brazil' },
    { value: '2084', label: 'Belize' },
    { value: '2086', label: 'British Indian Ocean Territory' },
    { value: '2090', label: 'Solomon Islands' },
    { value: '2092', label: 'British Virgin Islands' },
    { value: '2096', label: 'Brunei' },
    { value: '2100', label: 'Bulgaria' },
    { value: '2104', label: 'Myanmar (Burma)' },
    { value: '2108', label: 'Burundi' },
    { value: '2112', label: 'Belarus' },
    { value: '2116', label: 'Cambodia' },
    { value: '2120', label: 'Cameroon' },
    { value: '2124', label: 'Canada' },
    { value: '2132', label: 'Cabo Verde' },
    { value: '2136', label: 'Cayman Islands' },
    { value: '2140', label: 'Central African Republic' },
    { value: '2144', label: 'Sri Lanka' },
    { value: '2148', label: 'Chad' },
    { value: '2152', label: 'Chile' },
    { value: '2156', label: 'China' },
    { value: '2158', label: 'Taiwan' },
    { value: '2162', label: 'Christmas Island' },
    { value: '2166', label: 'Cocos (Keeling) Islands' },
    { value: '2170', label: 'Colombia' },
    { value: '2174', label: 'Comoros' },
    { value: '2175', label: 'Mayotte' },
    { value: '2178', label: 'Republic of the Congo' },
    { value: '2180', label: 'Democratic Republic of the Congo' },
    { value: '2184', label: 'Cook Islands' },
    { value: '2188', label: 'Costa Rica' },
    { value: '2191', label: 'Croatia' },
    { value: '2196', label: 'Cyprus' },
    { value: '2203', label: 'Czechia' },
    { value: '2204', label: 'Benin' },
    { value: '2208', label: 'Denmark' },
    { value: '2212', label: 'Dominica' },
    { value: '2214', label: 'Dominican Republic' },
    { value: '2218', label: 'Ecuador' },
    { value: '2222', label: 'El Salvador' },
    { value: '2226', label: 'Equatorial Guinea' },
    { value: '2231', label: 'Ethiopia' },
    { value: '2232', label: 'Eritrea' },
    { value: '2233', label: 'Estonia' },
    { value: '2234', label: 'Faroe Islands' },
    { value: '2238', label: 'Falkland Islands (Islas Malvinas)' },
    { value: '2239', label: 'South Georgia and the South Sandwich Islands' },
    { value: '2242', label: 'Fiji' },
    { value: '2246', label: 'Finland' },
    { value: '2250', label: 'France' },
    { value: '2254', label: 'French Guiana' },
    { value: '2258', label: 'French Polynesia' },
    { value: '2260', label: 'French Southern and Antarctic Lands' },
    { value: '2262', label: 'Djibouti' },
    { value: '2266', label: 'Gabon' },
    { value: '2268', label: 'Georgia' },
    { value: '2270', label: 'The Gambia' },
    { value: '2275', label: 'Palestine' },
    { value: '2276', label: 'Germany' },
    { value: '2288', label: 'Ghana' },
    { value: '2292', label: 'Gibraltar' },
    { value: '2296', label: 'Kiribati' },
    { value: '2300', label: 'Greece' },
    { value: '2304', label: 'Greenland' },
    { value: '2308', label: 'Grenada' },
    { value: '2312', label: 'Guadeloupe' },
    { value: '2316', label: 'Guam' },
    { value: '2320', label: 'Guatemala' },
    { value: '2324', label: 'Guinea' },
    { value: '2328', label: 'Guyana' },
    { value: '2332', label: 'Haiti' },
    { value: '2334', label: 'Heard Island and McDonald Islands' },
    { value: '2336', label: 'Vatican City' },
    { value: '2340', label: 'Honduras' },
    { value: '2344', label: 'Hong Kong' },
    { value: '2348', label: 'Hungary' },
    { value: '2352', label: 'Iceland' },
    { value: '2356', label: 'India' },
    { value: '2360', label: 'Indonesia' },
    { value: '2368', label: 'Iraq' },
    { value: '2372', label: 'Ireland' },
    { value: '2376', label: 'Israel' },
    { value: '2380', label: 'Italy' },
    { value: '2384', label: "Cote d'Ivoire" },
    { value: '2388', label: 'Jamaica' },
    { value: '2392', label: 'Japan' },
    { value: '2398', label: 'Kazakhstan' },
    { value: '2400', label: 'Jordan' },
    { value: '2404', label: 'Kenya' },
    { value: '2410', label: 'South Korea' },
    { value: '2414', label: 'Kuwait' },
    { value: '2417', label: 'Kyrgyzstan' },
    { value: '2418', label: 'Laos' },
    { value: '2422', label: 'Lebanon' },
    { value: '2426', label: 'Lesotho' },
    { value: '2428', label: 'Latvia' },
    { value: '2430', label: 'Liberia' },
    { value: '2434', label: 'Libya' },
    { value: '2438', label: 'Liechtenstein' },
    { value: '2440', label: 'Lithuania' },
    { value: '2442', label: 'Luxembourg' },
    { value: '2446', label: 'Macao' },
    { value: '2450', label: 'Madagascar' },
    { value: '2454', label: 'Malawi' },
    { value: '2458', label: 'Malaysia' },
    { value: '2462', label: 'Maldives' },
    { value: '2466', label: 'Mali' },
    { value: '2470', label: 'Malta' },
    { value: '2474', label: 'Martinique' },
    { value: '2478', label: 'Mauritania' },
    { value: '2480', label: 'Mauritius' },
    { value: '2484', label: 'Mexico' },
    { value: '2492', label: 'Monaco' },
    { value: '2496', label: 'Mongolia' },
    { value: '2498', label: 'Moldova' },
    { value: '2499', label: 'Montenegro' },
    { value: '2500', label: 'Montserrat' },
    { value: '2504', label: 'Morocco' },
    { value: '2508', label: 'Mozambique' },
    { value: '2512', label: 'Oman' },
    { value: '2516', label: 'Namibia' },
    { value: '2520', label: 'Nauru' },
    { value: '2524', label: 'Nepal' },
    { value: '2528', label: 'Netherlands' },
    { value: '2531', label: 'Curacao' },
    { value: '2533', label: 'Aruba' },
    { value: '2534', label: 'Sint Maarten' },
    { value: '2535', label: 'Caribbean Netherlands' },
    { value: '2540', label: 'New Caledonia' },
    { value: '2548', label: 'Vanuatu' },
    { value: '2554', label: 'New Zealand' },
    { value: '2558', label: 'Nicaragua' },
    { value: '2562', label: 'Niger' },
    { value: '2566', label: 'Nigeria' },
    { value: '2570', label: 'Niue' },
    { value: '2574', label: 'Norfolk Island' },
    { value: '2578', label: 'Norway' },
    { value: '2580', label: 'Northern Mariana Islands' },
    { value: '2581', label: 'United States Minor Outlying Islands' },
    { value: '2583', label: 'Micronesia' },
    { value: '2584', label: 'Marshall Islands' },
    { value: '2585', label: 'Palau' },
    { value: '2586', label: 'Pakistan' },
    { value: '2591', label: 'Panama' },
    { value: '2598', label: 'Papua New Guinea' },
    { value: '2600', label: 'Paraguay' },
    { value: '2604', label: 'Peru' },
    { value: '2608', label: 'Philippines' },
    { value: '2612', label: 'Pitcairn Islands' },
    { value: '2616', label: 'Poland' },
    { value: '2620', label: 'Portugal' },
    { value: '2624', label: 'Guinea-Bissau' },
    { value: '2626', label: 'Timor-Leste' },
    { value: '2630', label: 'Puerto Rico' },
    { value: '2634', label: 'Qatar' },
    { value: '2638', label: 'Reunion' },
    { value: '2642', label: 'Romania' },
    { value: '2646', label: 'Rwanda' },
    { value: '2654', label: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: '2659', label: 'Saint Kitts and Nevis' },
    { value: '2660', label: 'Anguilla' },
    { value: '2662', label: 'Saint Lucia' },
    { value: '2663', label: 'Saint Martin' },
    { value: '2666', label: 'Saint Pierre and Miquelon' },
    { value: '2670', label: 'Saint Vincent and the Grenadines' },
    { value: '2674', label: 'San Marino' },
    { value: '2678', label: 'Sao Tome and Principe' },
    { value: '2682', label: 'Saudi Arabia' },
    { value: '2686', label: 'Senegal' },
    { value: '2690', label: 'Seychelles' },
    { value: '2694', label: 'Sierra Leone' },
    { value: '2702', label: 'Singapore' },
    { value: '2703', label: 'Slovakia' },
    { value: '2704', label: 'Vietnam' },
    { value: '2705', label: 'Slovenia' },
    { value: '2706', label: 'Somalia' },
    { value: '2710', label: 'South Africa' },
    { value: '2716', label: 'Zimbabwe' },
    { value: '2724', label: 'Spain' },
    { value: '2732', label: 'Western Sahara' },
    { value: '2740', label: 'Suriname' },
    { value: '2744', label: 'Svalbard and Jan Mayen' },
    { value: '2748', label: 'Eswatini' },
    { value: '2752', label: 'Sweden' },
    { value: '2756', label: 'Switzerland' },
    { value: '2762', label: 'Tajikistan' },
    { value: '2764', label: 'Thailand' },
    { value: '2768', label: 'Togo' },
    { value: '2772', label: 'Tokelau' },
    { value: '2776', label: 'Tonga' },
    { value: '2780', label: 'Trinidad and Tobago' },
    { value: '2784', label: 'United Arab Emirates' },
    { value: '2788', label: 'Tunisia' },
    { value: '2792', label: 'Turkiye' },
    { value: '2795', label: 'Turkmenistan' },
    { value: '2796', label: 'Turks and Caicos Islands' },
    { value: '2798', label: 'Tuvalu' },
    { value: '2800', label: 'Uganda' },
    { value: '2804', label: 'Ukraine' },
    { value: '2807', label: 'North Macedonia' },
    { value: '2818', label: 'Egypt' },
    { value: '2826', label: 'United Kingdom' },
    { value: '2831', label: 'Guernsey' },
    { value: '2832', label: 'Jersey' },
    { value: '2833', label: 'Isle of Man' },
    { value: '2834', label: 'Tanzania' },
    { value: '2840', label: 'United States' },
    { value: '2850', label: 'U.S. Virgin Islands' },
    { value: '2854', label: 'Burkina Faso' },
    { value: '2858', label: 'Uruguay' },
    { value: '2860', label: 'Uzbekistan' },
    { value: '2862', label: 'Venezuela' },
    { value: '2876', label: 'Wallis and Futuna' },
    { value: '2882', label: 'Samoa' },
    { value: '2887', label: 'Yemen' },
    { value: '2894', label: 'Zambia' },
    { value: '20277', label: 'Canary Islands' },
  ]

  const formatOptions = [
    { label: 'All Formats', value: 'All Formats' },
    { label: 'Images', value: 'Images' },
    { label: 'Texts', value: 'Texts' },
    { label: 'Videos', value: 'Videos' },
  ]

  const handleCompanySearch = e => {
    const enteredValue = e.target.value
    dispatch({ type: ADS_SEARCH_COMPANY, payload: enteredValue })
  }
  const handleKeywordSearch = e => {
    let enteredValue = e.target.value
    if (enteredValue.startsWith('www.')) {
      enteredValue = enteredValue.slice(4)
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })
  }

  const handleSelectFormat = selectedValues => {
    setSelectedOptionsFormat(selectedValues)
    dispatch({ type: ADS_FORMAT, payload: selectedValues.includes('All') ? '' : selectedValues })
  }
  const handleSelectChannels = selectedValues => {
    setSelectedOptionsFormat([])
    dispatch({ type: ADS_FORMAT, payload: '' })
    dispatch({ type: ADS_LOCATION, payload: '' })
    setSelectedLocation([])
    setSelectedOptionsChannel(selectedValues)
    dispatch({ type: ADS_CHANNEL, payload: selectedValues })
  }

  const onChangeLocation = value => {
    dispatch({ type: ADS_LOCATION, payload: value })
    setSelectedLocation(value)
  }
  const onSearchLocation = value => {}
  const onDropdownVisibleChange = open => {
    setLocationPlaceholder(open ? 'Search by Country Name' : 'Location: Anywhere')
  }

  // Fetch Ads Data

  const handleAdsApi = async () => {
    dispatch({ type: ADS_LOADER, payload: true })
    dispatch({
      type: ADS_PAGINATION,
      payload: '',
    })
    dispatch({ type: SPINNER, payload: true })
    // dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    // emptyAdsData()

    const googleData = {
      region: location,
      search_term: searchKeyword,
      creative_format: adsFormats.toString().toLowerCase().replace(/s$/, ''),
      num: adsFormats === 'Videos' ? 20 : 100,
      channels: selectedChannel,
    }
    const metaData = {
      ad_reached_countries: [location],
      search_term: searchKeyword,
      creative_format: adsFormats.toString().toLowerCase().replace(/s$/, ''),
      num: 30,
      channels: selectedChannel,
    }
    if (selectedChannel === 'Google' || selectedChannel === 'G') {
      try {
        dispatch({ type: SPINNER, payload: true })
        const response = await RequestService.post('/ads-preview/competitors/', googleData)
        dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
        const formattedData = response?.data
        dispatch({ type: ADS_LOADER, payload: false })
        dispatch({ type: ADS_NO_DATA, payload: false })
        if (formattedData?.google_ads_result?.google_ads[0]?.ad_creatives) {
          dispatch({
            type: ADS_DATA,
            payload: formattedData?.google_ads_result?.google_ads[0]?.ad_creatives,
          })
        }
        if (formattedData?.google_ads_result?.google_ads[0]?.serpapi_pagination?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: formattedData?.google_ads_result?.google_ads[0]?.serpapi_pagination?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })

          if (adsFormats === 'Videos') {
            dispatch({ type: CLEANED_DATA_COUNT, payload: 20 })
          } else {
            dispatch({ type: CLEANED_DATA_COUNT, payload: 100 })
          }
        }
        if (!formattedData?.google_ads_result?.google_ads[0]?.ad_creatives) {
          dispatch({ type: ADS_NO_DATA, payload: true })
          dispatch({
            type: ADS_DATA,
            payload: [],
          })
        }
        dispatch({ type: SPINNER, payload: false })
      } catch {
        dispatch({ type: SPINNER, payload: false })
        dispatch({ type: ADS_LOADER, payload: false })
        dispatch({ type: ADS_NO_DATA, payload: true })
      }
    } else if (selectedChannel === 'Meta') {
      try {
        dispatch({ type: SPINNER, payload: true })
        const response = await RequestService.post('/ads-preview/competitors/', metaData)
        dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
        const formattedData = response?.data
        dispatch({ type: ADS_LOADER, payload: false })
        dispatch({ type: ADS_NO_DATA, payload: false })
        if (formattedData?.meta_ads?.facebook_ads[0]?.data) {
          dispatch({
            type: ADS_DATA,
            payload: formattedData?.meta_ads?.facebook_ads[0]?.data,
          })
        }
        if (formattedData?.meta_ads?.facebook_ads[0]?.paging?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: formattedData?.meta_ads?.facebook_ads[0]?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        }
        dispatch({ type: CLEANED_DATA_COUNT, payload: 20 })
        if (formattedData?.meta_ads?.facebook_ads[0]?.data.length === 0) {
          dispatch({ type: ADS_NO_DATA, payload: true })
          dispatch({
            type: ADS_DATA,
            payload: [],
          })
        }
        dispatch({ type: SPINNER, payload: false })
      } catch {
        dispatch({ type: SPINNER, payload: false })
        dispatch({ type: ADS_LOADER, payload: false })
        dispatch({ type: ADS_NO_DATA, payload: true })
      }
    }
  }

  const handleSearchButton = () => {
    if (searchKeyword.trim() !== '') {
      // dispatch({ type: META_ADS_PAGINATION_DATA, payload: [] })
      dispatch({ type: ADS_HOME_STATE, payload: false })
      dispatch({ type: CLEANED_DATA_COUNT, payload: '' })
      dispatch({
        type: HAS_MORE,
        payload: false,
      })

      handleAdsApi()
    } else {
      notify('Search Keyword cannot be empty', 'error')
    }
  }

  return (
    <div style={{ background: '#fff' }}>
      <Row gutter={12} className={styles.parentDiv}>
        <Col span={7} className={styles.inputContainer}>
          {/* <div id="search1" className={styles.input}>
            <Input
              id="companySearchInput"
              className={styles.inputText}
              placeholder="Search by company or advertiser name"
              prefix={
                // <SearchOutlined style={{ width: 24, height: 24, color: '#8F8F8F' }} />
                <img src={companySearchIcon} height={24} width={24} alt="search-icon" />
              }
              onPressEnter={handleCompanySearch}
            />
          </div> */}

          {/* Start of Keyword Input field */}
          <div className={styles.input}>
            <Input
              id="keywordSearchInput"
              className={styles.inputText}
              placeholder="Enter a domain or keyword"
              prefix={
                // <SearchOutlined style={{ width: 24, height: 24, color: '#8F8F8F' }} />
                <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
              }
              onChange={handleKeywordSearch}
              onPressEnter={loading || moreDataLoader ? '' : handleSearchButton}
            />
          </div>
          {/* End of Keyword Input field */}
        </Col>

        {/* Start of Location Dropdown */}
        <Col span={4} style={{ position: 'relative' }} id="area">
          {selectedChannel === 'Google' || selectedChannel === 'G' ? (
            <Select
              id="location"
              value={selectedLocation}
              showSearch
              placeholder={locationPlaceholder}
              onDropdownVisibleChange={onDropdownVisibleChange}
              optionFilterProp="children"
              onChange={onChangeLocation}
              onSearch={onSearchLocation}
              dropdownClassName="ads-preview-dropdown"
              getPopupContainer={() => document.getElementById('area')}
              style={{
                display: 'block',
                fontSize: 14,
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map(country => (
                <Option
                  key={country.value}
                  value={country.value}
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '16px',
                  }}
                >
                  {country.label}
                </Option>
              ))}
            </Select>
          ) : (
            <Select
              id="location"
              showSearch
              value={selectedLocation}
              placeholder={locationPlaceholder}
              onDropdownVisibleChange={onDropdownVisibleChange}
              optionFilterProp="children"
              onChange={onChangeLocation}
              onSearch={onSearchLocation}
              dropdownClassName="ads-preview-dropdown"
              getPopupContainer={() => document.getElementById('area')}
              style={{
                display: 'block',
                fontSize: 14,
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Metacountries.map(country => (
                <Option
                  key={country.value}
                  value={country.value}
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '16px',
                  }}
                >
                  {country.label}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        {/* End of Location Dropdown */}

        {/* <div id="AdsPreviewSearchSelect"> */}
        <Col span={5} style={{ position: 'relative' }} id="area">
          <Select
            id="channels"
            // mode="multiple"
            placeholder="Channels: All Channels"
            style={{
              display: 'block',
              fontSize: 14,
            }}
            // mode="multiple"
            dropdownClassName="ads-preview-dropdown"
            onChange={handleSelectChannels}
            value={selectedOptionsChannel}
            showArrow
            showSearch={false}
            getPopupContainer={() => document.getElementById('area')}
            optionLabelProp="label"
            maxTagCount="responsive"
          >
            {/* <Option value="All Channels" disabled style={{ color: 'gray' }}>
              <div className={styles.options}>
                <Checkbox
                  disabled
                  id="ads-checkbox"
                  checked={selectedOptionsChannel.includes('All Channels')}
                />
                <span className={styles.checkbox}>All Channels</span>
              </div>
            </Option> */}

            <Option value="Google">
              <div className={styles.options}>
                <Checkbox checked={selectedOptionsChannel.includes('Google')} />
                <span className={styles.checkbox}>Google</span>
              </div>
            </Option>
            {/* <Option value="Meta">
              <div className={styles.options}>
                <Checkbox checked={selectedOptionsChannel.includes('Meta')} />
                <span className={styles.checkbox}>Meta</span>
              </div>
            </Option> */}
            {/* <Option value="Pinterest" disabled style={{ color: 'gray' }}>
              <div className={styles.options}>
                <Checkbox disabled checked={selectedOptionsChannel.includes('Pinterest')} />
                <span className={styles.checkbox}>Pinterest</span>
              </div>
            </Option>
            <Option value="Twitter" disabled style={{ color: 'gray' }}>
              <div className={styles.options}>
                <Checkbox disabled checked={selectedOptionsChannel.includes('Twitter')} />
                <span className={styles.checkbox}>Twitter</span>
              </div>
            </Option>
            <Option value="LinkedIn" disabled style={{ color: 'gray' }}>
              <div className={styles.options}>
                <Checkbox disabled checked={selectedOptionsChannel.includes('LinkedIn')} />
                <span className={styles.checkbox}>LinkedIn</span>
              </div>
            </Option> */}
          </Select>
        </Col>
        {/* </div> */}
        {/* <div id="AdsPreviewSearchSelect"> */}

        {/* Start of Formats dropdown */}
        <Col span={4}>
          {
            // eslint-disable-next-line
            selectedChannel === 'Google' || selectedChannel === 'G' ? (
              <Select
                placeholder="Ad Formats: All formats"
                style={{
                  // flex: 1,
                  display: 'block',
                  fontSize: 14,
                  borderColor: '#E8EBEC !important',
                }}
                // mode="multiple"
                dropdownClassName="ads-preview-dropdown"
                onChange={handleSelectFormat}
                showArrow
                value={selectedOptionsFormat}
                showSearch={false}
                getPopupContainer={() => document.getElementById('area')}
                optionLabelProp="label"
                maxTagCount="responsive"
              >
                <Option value="All">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('All')} /> */}
                    <span className={styles.checkbox}>All</span>
                  </div>
                </Option>
                <Option value="Images">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('Images')} /> */}
                    <span className={styles.checkbox}>Images</span>
                  </div>
                </Option>
                <Option value="Texts">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('Texts')} /> */}
                    <span className={styles.checkbox}>Texts</span>
                  </div>
                </Option>
                <Option value="Videos">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('Videos')} /> */}
                    <span className={styles.checkbox}>Videos</span>
                  </div>
                </Option>
              </Select>
            ) : selectedChannel === 'Meta' ? (
              <Select
                placeholder="Ad Formats: All formats"
                style={{
                  // flex: 1,
                  display: 'block',
                  fontSize: 14,
                  borderColor: '#E8EBEC !important',
                }}
                // mode="multiple"
                dropdownClassName="ads-preview-dropdown"
                onChange={handleSelectFormat}
                showArrow
                value={selectedOptionsFormat}
                showSearch={false}
                getPopupContainer={() => document.getElementById('area')}
                optionLabelProp="label"
                maxTagCount="responsive"
              >
                <Option value="All">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('All')} /> */}
                    <span className={styles.checkbox}>All</span>
                  </div>
                </Option>
                <Option value="Images">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('Images')} /> */}
                    <span className={styles.checkbox}>Images</span>
                  </div>
                </Option>
                {/* <Option value="Meme">
                  <div className={styles.options}> */}
                {/* <Radio checked={selectedOptionsFormat.includes('Texts')} /> */}
                {/* <span className={styles.checkbox}>Meme</span>
                  </div>
                </Option> */}
                <Option value="Videos">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('Videos')} /> */}
                    <span className={styles.checkbox}>Videos</span>
                  </div>
                </Option>
              </Select>
            ) : (
              ''
            )
          }
        </Col>
        {/* End of Search Queries Collapse */}

        <Col span={4}>
          <button
            type="submit"
            className={styles.searchButton}
            onClick={handleSearchButton}
            disabled={loading || moreDataLoader}
          >
            SEARCH
          </button>
        </Col>
        {/* </div> */}
        {/* </div> */}
      </Row>
    </div>
  )
}

export default SearchBar
